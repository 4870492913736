import type { GHtml, TeaserGridItemParserFn } from '@hubcms/domain-teaser-grid';
import { HtmlEmbed } from '@hubcms/ui-embed';

export const parseHtml: TeaserGridItemParserFn<GHtml, typeof HtmlEmbed> = (item, idx) => ({
  Component: HtmlEmbed,
  componentProps: {
    htmlBlock: item.data.html,
    style: { height: '100%' },
  },
  key: `html-embed-${idx}`,
});
