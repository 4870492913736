import type { GGrid, TeaserGridItemParserFn } from '@hubcms/domain-teaser-grid';

import { Areas } from '../areas';

export const parseGrid: TeaserGridItemParserFn<GGrid, Exclude<typeof Areas.grid, undefined>> = (item, idx: number) => {
  return {
    Component: Areas.grid,
    key: `inner-area-${idx}`,
    componentProps: {
      ...item.data,
    },
  };
};
