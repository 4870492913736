import type { GTitle, TeaserGridItemParserFn } from '@hubcms/domain-teaser-grid';
import { BrandedHeading } from '@hubcms/ui-branded-header';

export const parseTitle: TeaserGridItemParserFn<GTitle, typeof BrandedHeading> = (item, idx) => ({
  key: `title-${idx}`,
  Component: BrandedHeading,
  componentProps: {
    title: item.data.text,
    headerLevel: item.data.headerLevel,
    href: item.data.link?.url,
    linkText: item.data.link?.text,
    target: item.data.link?.target,
    size: item.data.size || 'lg',
    trackingData: item.data.link?.trackingData,
  },
});
