import cx from 'classnames';

import type { ListItemProps } from './types';
import styles from './list.module.scss';

export default function ListItem({ children, className, isLastofColumn, style, myRef, ...props }: ListItemProps) {
  return (
    <li
      className={cx(className, styles.listItem, { [styles.lastOfColumn]: isLastofColumn })}
      ref={myRef}
      style={style}
      {...props}
    >
      {children}
    </li>
  );
}
