import type { GArea, GSingleItem, TTeaserGridItem, TeaserGridItemParserFn } from '@hubcms/domain-teaser-grid';

import {
  parseDefaultTeaser,
  parseGrid,
  parseHtml,
  parseLegacyTeaser,
  parseList,
  parseListTeaser,
  parseTextOnImageTeaser,
  parseNewsletter,
  parseTitle,
} from './parsers';

type DefaultItemType = Exclude<GSingleItem['type'], 'ad'> | GArea['type'];
const defaultTeaserGridItemParsers: Record<DefaultItemType, TeaserGridItemParserFn<never>> = {
  title: parseTitle,
  html: parseHtml,
  'legacy-teaser': parseLegacyTeaser,
  'default-teaser': parseDefaultTeaser,
  'text-on-image-teaser': parseTextOnImageTeaser,
  'list-teaser': parseListTeaser,
  grid: parseGrid,
  list: parseList,
  newsletter: parseNewsletter,
};

const hasInverseMarginInlineSm: DefaultItemType[] = ['grid', 'list'];

export function parseItemData(
  item: TTeaserGridItem<string, unknown>,
  idx: number,
  extensions: Record<string, TeaserGridItemParserFn<never>> = {},
) {
  if (!item) {
    return null;
  }

  const gridProps = item.gridProps ?? {};

  if (
    typeof gridProps.hasInverseMarginInlineSm === 'undefined' &&
    hasInverseMarginInlineSm.includes(item.type as DefaultItemType)
  ) {
    gridProps.hasInverseMarginInlineSm = typeof item.data === 'object' && item.data !== null && !('scroll' in item.data);
  }

  if (item.type === 'list') {
    gridProps.display = gridProps.display === 'none' ? 'none' : 'flex';
    gridProps.displayMd = gridProps.displayMd === 'none' ? 'none' : 'flex';
    gridProps.displayLg = gridProps.displayLg === 'none' ? 'none' : 'flex';
  }

  const mapper = getDefaultMapper(item.type) ?? extensions[item.type];

  if (!mapper) {
    return null;
  }

  return {
    gridProps,
    ...mapper(item as never, idx),
  };
}

function getDefaultMapper<T extends string>(itemType: T extends DefaultItemType ? T : string) {
  if (Object.keys(defaultTeaserGridItemParsers).includes(itemType)) {
    return defaultTeaserGridItemParsers[itemType as DefaultItemType];
  }
  return null;
}
