import { Icon } from '@mediahuis/chameleon-react';
import { Bullet } from '@mediahuis/chameleon-theme-wl/icons';
import cx from 'classnames';

import styles from './sliderdots.module.scss';

type SliderDotsProps = {
  activeDotIndex: number | number[];
  className?: string;
  nrOfDots: number;
};

function SliderDots({ activeDotIndex, className, nrOfDots, ...props }: SliderDotsProps) {
  const activeDotIndices = Array.isArray(activeDotIndex) ? activeDotIndex : [activeDotIndex];

  const sliderDots = reduceSliderDotsToMaxAmount(createSliderDots(nrOfDots, activeDotIndices));

  return (
    <div className={cx(styles.indicatorDots, className)} {...props}>
      {sliderDots.map(scrollDot => (
        <div className={styles.singleDot} key={`dot_${scrollDot.index}`}>
          <Icon as={Bullet} className={cx(styles.dotIcon, { [styles.active]: scrollDot.isActive })} />
        </div>
      ))}
    </div>
  );
}

interface SliderDot {
  index: number;
  isActive: boolean;
}

function createSliderDots(amount: number, activeDotIndices: number[]): SliderDot[] {
  return Array.from({ length: amount }).map((_, index) => ({ index, isActive: activeDotIndices.includes(index) }));
}

function reduceSliderDotsToMaxAmount(sliderDots: SliderDot[]): SliderDot[] {
  const maxAmount = 5;

  if (sliderDots.length > maxAmount) {
    const middleDots = sliderDots.slice(2, -2);

    return [
      sliderDots.at(0) as SliderDot,
      sliderDots.at(1) as SliderDot,
      {
        index: 2,
        isActive: middleDots.some(middleDot => middleDot.isActive),
      },
      {
        ...(sliderDots.at(-2) as SliderDot),
        index: 3,
      },
      {
        ...(sliderDots.at(-1) as SliderDot),
        index: 4,
      },
    ];
  }

  return sliderDots;
}

export default SliderDots;
