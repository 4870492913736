import { useRef, useState, useCallback } from 'react';

export function useIntersections(ignoreIndex?: number) {
  const [intersectionsByIndex, setIntersectionsByIndex] = useState<Record<number, boolean>>({});
  const intersectingRef = useRef<number[]>([0]);
  const hasIgnoreIndex = ignoreIndex !== undefined && ignoreIndex !== -1;

  const setIntersections = useCallback(
    (index: number, isIntersecting: boolean) => {
      if (ignoreIndex === index) {
        return;
      }

      const computedIndex = hasIgnoreIndex && index > ignoreIndex ? index - 1 : index;

      setIntersectionsByIndex(state => {
        return {
          ...state,
          [computedIndex]: isIntersecting,
        };
      });
    },
    [ignoreIndex, hasIgnoreIndex],
  );

  const hasIntersecting = Object.values(intersectionsByIndex).includes(true);
  const hasNonIntersecting = Object.values(intersectionsByIndex).includes(false);

  if (hasIntersecting) {
    intersectingRef.current = Object.entries(intersectionsByIndex)
      .filter(([, value]) => value === true)
      .map(([key]) => Number(key));
  }

  const intersectingIndexes = intersectingRef.current;

  return { intersectingIndexes, setIntersections, hasIntersecting, hasNonIntersecting };
}
