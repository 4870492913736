import type { AreaScroll } from '@hubcms/domain-teaser-grid';
import type { CSSProperties, MutableRefObject, PropsWithChildren } from 'react';

import { useIntersections } from '@hubcms/utils-browser';
import cx from 'classnames';
import { useRef } from 'react';

import { SliderDots } from '../SliderDots';
import { ScrollWrapperContext } from './context';

import styles from './scroll-wrapper.module.scss';
import { isScrollWithAtLeastOneBreakpoint } from '../../utils/isScrollWithAtLeastOneBreakpoint';

type ScrollWrapperProps = {
  areaScroll: AreaScroll;
  isHiddenSm?: boolean;
  isHiddenMd?: boolean;
  style?: CSSProperties;
  withPaddingBottom?: boolean;
};

function ScrollWrapper({
  areaScroll,
  children,
  isHiddenSm,
  isHiddenMd,
  withPaddingBottom,
  ...props
}: PropsWithChildren<ScrollWrapperProps>) {
  const { breakpoints, hasPadding, hasNoPaddingTop, ignoreIntersectionOnIndex, nrOfDots, snap } = areaScroll;

  const parentRef = useRef() as MutableRefObject<HTMLDivElement>;

  const { hasNonIntersecting, intersectingIndexes, setIntersections } = useIntersections(ignoreIntersectionOnIndex);
  const providerValue =
    areaScroll && isScrollWithAtLeastOneBreakpoint(areaScroll)
      ? null
      : { intersection: { setIntersections }, rootNode: parentRef.current };

  return (
    <ScrollWrapperContext.Provider value={providerValue}>
      <div
        className={cx({
          [styles.scrollWrapperXs]: breakpoints?.xs || !breakpoints,
          [styles.scrollWrapperSm]: breakpoints?.sm || !breakpoints,
          [styles.scrollWrapperMd]: breakpoints?.md,
          [styles.scrollWrapperLg]: breakpoints?.lg,
          [styles.scrollWrapperXl]: breakpoints?.xl,
          [styles.withPadding]: hasPadding,
          [styles.withPaddingBottom]: !hasPadding && withPaddingBottom,
          [styles.withoutPaddingTop]: hasNoPaddingTop,
          [styles.hiddenSm]: isHiddenSm,
          [styles.hiddenMd]: isHiddenMd,
          [styles.snap]: snap,
        })}
        ref={parentRef}
        {...props}
      >
        {children}
      </div>

      {!!nrOfDots && nrOfDots > 0 && hasNonIntersecting && (
        <SliderDots activeDotIndex={intersectingIndexes} className={styles.sliderDots} nrOfDots={nrOfDots} {...props} />
      )}
    </ScrollWrapperContext.Provider>
  );
}

export default ScrollWrapper;
