import type { StoryblockTeaserSizesConfig } from '@hubcms/domain-storyblock';

export const storyblockTeaserSizesConfig: StoryblockTeaserSizesConfig = {
  opening: isHero => (isHero ? { xs: 'md', md: 'lg' } : { xs: 'sm', md: 'md', lg: 'sm' }),
  regular: (isHero, hasSlider) => (isHero || hasSlider ? 'md' : { xs: 'sm', md: 'md' }),
  lifestyle: isHero => (isHero ? 'lg' : { xs: 'sm', md: 'md', lg: 'sm' }),
  visualstory: 'md',
};

export const storyblockHeadingWithBottomGap = true;
