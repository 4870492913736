import { isPlaceholderTeaserData } from '@hubcms/domain-teaser';
import type { GTextOnImageTeaser, TeaserGridItemParserFn, TeaserGridItemTuple } from '@hubcms/domain-teaser-grid';
import { TextOnImageTeaser, TextOnImageTeaserPlaceholder } from '@hubcms/ui-teaser';

export const parseTextOnImageTeaser: TeaserGridItemParserFn<GTextOnImageTeaser> = (
  item,
  idx,
): TeaserGridItemTuple<typeof TextOnImageTeaserPlaceholder | typeof TextOnImageTeaser> => {
  if (isPlaceholderTeaserData(item.data.teaserData)) {
    const tuple: TeaserGridItemTuple<typeof TextOnImageTeaserPlaceholder> = {
      key: `placeholder-${idx}`,
      Component: TextOnImageTeaserPlaceholder,
      componentProps: {
        headerLevel: item.data.headerLevel || 3,
        hasDuration: item.data.hasDateTime,
        hasIntro: !!item.data.hasIntro,
        hasLabel: false,
        imageFormat: item.data.imageFormat,
        size: item.data.size,
      },
    };
    return tuple;
  }
  const { url: link, trackingData, ...teaserData } = item.data.teaserData;
  const key = `${teaserData.id}-${idx}`;

  return {
    key,
    Component: TextOnImageTeaser,
    componentProps: {
      ...teaserData,
      headerLevel: item.data.headerLevel || 3,
      hasDateTime: item.data.hasDateTime,
      hasIntro: item.data.hasIntro,
      link,
      image: typeof teaserData.image === 'string' ? null : teaserData.image,
      imageFormat: item.data.imageFormat,
      imageSizes: item.data.imageSizes,
      isFullHeight: item.data.isFullHeight,
      isImagePriority: item.data.isPriority,
      size: item.data.size,
      trackingData: { ...trackingData, ...item.data.trackingData },
    },
  };
};
