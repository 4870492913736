import { createContext, useContext } from 'react';

export interface ScrollWrapperContextValue {
  intersection: {
    setIntersections: (index: number, isIntersecting: boolean) => void;
  };
  rootNode: HTMLElement | null;
}

export const ScrollWrapperContext = createContext<ScrollWrapperContextValue | null>(null);

export function useScrollWrapperContext() {
  const scrollWrapperContext = useContext(ScrollWrapperContext);

  return scrollWrapperContext;
}

export default useScrollWrapperContext;
