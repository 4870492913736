import { Divider } from '@mediahuis/chameleon-react';
import cx from 'classnames';
import type { PropsWithChildren } from 'react';

import type { CssVariable } from '@hubcms/domain-styling';
import type { AreaScroll } from '@hubcms/domain-teaser-grid';
import { getThemeDataAttributes } from '@hubcms/utils-theme';

import { useAreaTitle } from '../../hooks/useAreaTitle';
import { Areas } from '../../utils/areas';
import { parseItemData } from '../../utils/parseItemData';
import { isScrollWithAtLeastOneBreakpoint } from '../../utils/isScrollWithAtLeastOneBreakpoint';
import { ScrollableItemWrapper, ScrollWrapper } from '../ScrollWrapper';
import { useTeaserGridExtensionsContext } from '../TeaserGridExtensions';

import TeaserGridItem from './TeaserGridItem';
import TeaserList from './TeaserList';
import styles from './teaser-grid.module.scss';
import type { TeaserGridProps } from './types';

import type { GridElement } from '../../types/GridElement';

// eslint-disable-next-line react/jsx-no-useless-fragment
const DefaultTeaserGridWrapper = ({ children }: PropsWithChildren) => <>{children}</>;

function TeaserGrid({
  bgColor,
  gridAutoFlowLg = 'row',
  gridColumns = 1,
  gridColumnsMd = 2,
  gridColumnsLg = 4,
  gridAutoRows = '1fr',
  hasRowGap = true,
  hasInlinePadding = true,
  hasNoGridDivider = false,
  height = 'auto',
  isHiddenSm,
  isHiddenMd,
  isRoot,
  isTransparentTheme,
  items,
  scroll,
  theme,
  title,
}: TeaserGridProps) {
  const { extensions } = useTeaserGridExtensionsContext();

  const { hasTitle, titleElement } = useAreaTitle(title, { isRoot });

  const themeDataAttributes = theme ? getThemeDataAttributes(theme) : null;

  let backgroundColor = bgColor;
  if (theme && theme !== 'none' && !isTransparentTheme && !bgColor) {
    backgroundColor = 'var(--semantic-background-contextual-adaptive-default-fill)';
  }

  const cssVariables: CssVariable = {
    '--i-grid-columns': typeof gridColumns === 'number' ? gridColumns : 1,
    '--i-grid-columns-md': typeof gridColumnsMd === 'number' ? gridColumnsMd : 2,
    '--i-grid-columns-lg': typeof gridColumnsLg === 'number' ? gridColumnsLg : 4,
    '--i-grid-template-columns': typeof gridColumns === 'string' ? gridColumns : 'repeat(var(--i-grid-columns), 1fr)',
    '--i-grid-template-columns-md': typeof gridColumnsMd === 'string' ? gridColumnsMd : 'repeat(var(--i-grid-columns-md), 1fr)',
    '--i-grid-template-columns-lg': typeof gridColumnsLg === 'string' ? gridColumnsLg : 'repeat(var(--i-grid-columns-lg), 1fr)',
    '--i-height': height,
    '--i-grid-auto-flow-lg': gridAutoFlowLg,
    '--i-grid-auto-rows': gridAutoRows,
    '--i-background-color': backgroundColor,
  };

  const scrollOnSm = !!scroll && (!scroll.breakpoints || scroll.breakpoints.sm || scroll.breakpoints.xs);
  const divider = hasNoGridDivider || scrollOnSm ? null : <Divider className={styles.divider} />;
  const TeaserGridWrapper = scroll && isScrollWithAtLeastOneBreakpoint(scroll) ? ScrollWrapper : DefaultTeaserGridWrapper;

  const gridElements = items
    .map((item, itemIndex) => parseItemData(item, itemIndex, extensions))
    .filter((gridElement): gridElement is GridElement => Boolean(gridElement?.Component))
    .map((element, elementIndex, elementsArray) => {
      const { Component, key, componentProps, gridProps } = element;

      const hasDivider = elementIndex !== elementsArray.length - 1 && !gridProps?.hasNoGridDivider;

      return (
        <ScrollableItemWrapper
          index={elementIndex}
          key={key}
          render={itemRef => (
            <>
              <TeaserGridItem
                key={key}
                gridProps={gridProps}
                itemRef={itemRef}
                style={{
                  scrollSnapAlign: scroll?.snap ? 'center' : undefined,
                }}
              >
                <Component {...componentProps} />
              </TeaserGridItem>

              {hasDivider && divider}
            </>
          )}
        />
      );
    });

  return (
    <TeaserGridWrapper
      areaScroll={scroll as AreaScroll}
      isHiddenSm={isHiddenSm}
      isHiddenMd={isHiddenMd}
      withPaddingBottom
      {...themeDataAttributes}
      style={{ backgroundColor }}
    >
      <div
        role="group"
        className={cx(styles.teaserGrid, {
          [styles.withTitle]: hasTitle,
          [styles.noRowGap]: !hasRowGap,
          [styles.withInlinePaddingXs]:
            hasInlinePadding === true || (typeof hasInlinePadding === 'object' && hasInlinePadding.xs),
          [styles.withInlinePaddingSm]:
            hasInlinePadding === true || (typeof hasInlinePadding === 'object' && hasInlinePadding.sm),
          [styles.withInlinePaddingMd]:
            hasInlinePadding === true || (typeof hasInlinePadding === 'object' && hasInlinePadding.md),
          [styles.withInlinePaddingLg]:
            hasInlinePadding === true || (typeof hasInlinePadding === 'object' && hasInlinePadding.lg),
          [styles.withInlinePaddingXl]:
            hasInlinePadding === true || (typeof hasInlinePadding === 'object' && hasInlinePadding.xl),
          [styles.hiddenSm]: isHiddenSm,
          [styles.hiddenMd]: isHiddenMd,
        })}
        {...themeDataAttributes}
        style={cssVariables}
      >
        {titleElement}
        {gridElements}
      </div>
    </TeaserGridWrapper>
  );
}

Areas.grid = TeaserGrid;
Areas.list = TeaserList;

export default TeaserGrid;
