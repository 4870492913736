import type { GLegacyTeaser, TeaserGridItemParserFn } from '@hubcms/domain-teaser-grid';
import { OpinionBlockTeaser } from '@hubcms/ui-teaser';

export const parseTeaser: TeaserGridItemParserFn<GLegacyTeaser> = (item, idx) => {
  const key = item.data.teaserData.id;

  const teaserElementData = item.data.options.sm ? mapTeaser(item.data, idx) : null;
  if (teaserElementData) {
    return {
      ...teaserElementData,
      key,
    };
  }
  return {
    key,
    Component: 'div',
  };
};

function mapTeaser(data: GLegacyTeaser['data'], idx: number) {
  return {
    Component: OpinionBlockTeaser,
    componentProps: {
      data: data.teaserData,
      hideIntro: data.options.hideIntro,
      odd: idx % 2 === 0,
      mobileRow: idx !== 0,
    },
  };
}
